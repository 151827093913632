import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "services/common/rtkQueryConfig";

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["dashboard", "document", "document-dealApplication"],
  endpoints: (build) => ({
    getDashboardItems: build.query<DashboardItemsResponseDto, string>({
      query: (id) => `/dashboard/user/${id}`,
      providesTags: ["dashboard", "document", "document-dealApplication"],
    }),
    setStatusFlagOfUser: build.mutation<void, string>({
      query: (id) => ({
        url: `/dashboard/${id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["dashboard"],
    }),
    uploadDocumentsWithSpecificCondition: build.mutation<string, FormData>({
      query: (body: FormData) => ({
        url: `/dashboard/UploadDocumentsWithSpecificConditionAsync`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["document"],
    }),
    UploadDocumentsApplication: build.mutation<string, FormData>({
      query: (body: FormData) => ({
        url: `/dashboard/UploadDocumentsApplicationAsync`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["document"],
    }),
    UploadDocumentsForDealApplication: build.mutation<string, FormData>({
      query: (body: FormData) => ({
        url: `/dashboard/UploadDocuments/deal-application`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["document-dealApplication"],
    }),
    UpdateDocumentIds: build.mutation<boolean, string>({
      query: (applicationId) => ({
        url: `/dashboard/UpdateDocumentIds/${applicationId}`,
        method: "POST",
      }),
      invalidatesTags: ["document"],
    }),
    UpdateDocumentIdsForDealApplication: build.mutation<boolean, string>({
      query: (dealApplicationId) => ({
        url: `/dashboard/UpdateDocumentIds/deal-application/${dealApplicationId}`,
        method: "POST",
      }),
      invalidatesTags: ["document-dealApplication"],
    }),
    deleteDocument: build.mutation<boolean, FormData>({
      query: (formData: FormData) => ({
        url: "/dashboard/DeleteDocument",
        method: "DELETE",
        body: formData,
      }),
      invalidatesTags: ["document"],
    }),
    deleteDocumentForDealApplication: build.mutation<boolean, FormData>({
      query: (formData: FormData) => ({
        url: "/dashboard/DeleteDocument/deal-application",
        method: "DELETE",
        body: formData,
      }),
      invalidatesTags: ["document-dealApplication"],
    }),
    deleteFailedDocuments: build.mutation<void, string>({
      query: (userId: string) => ({
        url: `/dashboard/user/${userId}/documents`,
        method: "DELETE",
      }),
    }),
    retrieveDocuments: build.query<RetrieveDocumentsResponseDto[], string>({
      query: (applicationId) =>
        `/dashboard/RetrieveDocumentsAsync/${applicationId}`,
      keepUnusedDataFor: 0.2,
    }),
    retrieveDocumentsForDealApplication: build.query<
      RetrieveDocumentsResponseDto[],
      string
    >({
      query: (applicationId) => `/dashboard/deal-application/${applicationId}`,
      keepUnusedDataFor: 0.2,
    }),
    retrieveDocumentContent: build.query<RetrieveDocumentContent, string>({
      query: (fileId) => `/dashboard/RetrieveDocumentContentAsync/${fileId}`,
    }),
    maticResponse: build.mutation<string, string>({
      query: (userId) => ({
        url: `/dashboard/user/${userId}/redirect-matic`,
        method: "POST",
        responseHandler: (response) => response.text(),
      }),
    }),
    getLoanStatus: build.query<LoanStatus, string>({
      query: (userId) => `/dashboard/user/${userId}/loan-status`,
    }),
    getDashboardBanner: build.query<DashboardAdDto, string>({
      query: (userId) => `/dashboard/user/${userId}/ad`,
    }),
    getMyLoans:build.query<LoanInfo[], void>({
      query: () => `/dashboard/user/my-loans`,
    }),
  }),
});

export const {
  useGetDashboardItemsQuery,
  useSetStatusFlagOfUserMutation,
  useUploadDocumentsWithSpecificConditionMutation,
  useUploadDocumentsApplicationMutation,
  useUploadDocumentsForDealApplicationMutation,
  useUpdateDocumentIdsMutation,
  useUpdateDocumentIdsForDealApplicationMutation,
  useDeleteDocumentMutation,
  useDeleteDocumentForDealApplicationMutation,
  useDeleteFailedDocumentsMutation,
  useRetrieveDocumentsQuery,
  useRetrieveDocumentsForDealApplicationQuery,
  useRetrieveDocumentContentQuery,
  useMaticResponseMutation,
  useGetLoanStatusQuery,
  useGetDashboardBannerQuery,
  useGetMyLoansQuery,
} = dashboardApi;

export interface DashboardItemsResponseDto {
  loanStatus: LoanStatus;
  propertyInfo: PropertyInfo;
  closingDate?: Date;
  pendingItems: PendingItem[];
  isNew: boolean;
}

export type LoanStatusType =
  | "Started"
  | "Processing"
  | "Underwriting"
  | "Approval"
  | "FinalReview"
  | "Close"
  | "Funded"
  | "Unknown";

export type LoanSubStatusType = "UnderReview" | "ConditionallyApproved";

interface DashboardItemsRequestDto {
  userId: string;
  inquiryId: string;
}

interface DashboardAdDto {
  image: string;
  logo: string;
  imageText: string;
  description1: string;
  description2: string;
}

export interface LoanStatus {
  status: LoanStatusType;
  subStatus?: LoanSubStatusType;
}

interface PropertyInfo {
  street: string;
  city: string;
  state: string;
  zipCode: string;
  loanNumber: string;
  loanAmount: number;
  loanType: string;
}

export interface PendingItem {
  title: string;
  status: "New" | "Submitted" | "Error" | "Pending Approval" | "Returned";
  id: string;
}

export interface AnyDocumentvalues {
  id: number;
  fileName: string;
  timeZone: string;
}

export interface RetrieveDocumentContent {
  fileContents: string;
  contentType: string;
}

export interface RetrieveDocumentsResponseDto {
  fileId: string;
  name: string;
  documentPath: string;
  dateCreated: string;
  category: string;
  providedBy: DocumentsProvider;
}

export enum DocumentsProvider {
  Unknown = "Unknown",
  User = "User",
  NAF = "Naf"
}

export interface DocumentsRequestDto {
  applicationId?: string;
}

export interface DocumentsRequestForDealApplicationDto {
  dealApplicationId?: string;
}

export interface LoanInfo {
  property: string;
  loanPurpose: string;
  loanAmount: string;
  status: ApplicationLoanStatus;
  startedDate: string;
  documentStatus?: boolean;
}

export type ApplicationLoanStatus =
  | "new"
  | "incomplete"
  | "started"
  | "processing"
  | "submittedToUnderwriting"
  | "conditionallyApproved"
  | "finalReview"
  | "clearedToClosed"
  | "funded";
